<template>
  <div>
    <section v-for="item in products" :key="item.title">
      <v-container grid-list-xs>
        <div style="height: 30px"></div>
        <v-layout row wrap justify-center align-center>
          <v-flex xs12 sm12 md4 lg4 pa-2>
            <v-img
              :src="item.img"
              width="100%"
              height="100%"
              max-height="350px"
              contain
            ></v-img>
          </v-flex>
          <v-flex xs12 sm12 md6 lg6 pa-2>
            <h1 class="display-1 b">Product Description</h1>
            <p class="body-1">{{ item.desc }}</p>
            <h1 class="body-1">
              <span class="b">Weight:</span>
              {{ item.weight }}
            </h1>
            <h1 class="body-1">
              <span class="b">Price:</span>
              {{ item.price }}
            </h1>
            <div class="mt-3">
              <v-btn
                color="primaryDark"
                class="text-capitalize"
                dark
                @click="$router.push({ name: 'order' })"
              >
                <v-icon small class="mr-3">mdi-cart</v-icon>
                Order Now</v-btn
              >
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      products: [
        {
          img: require("../../assets/product/p1.png"),
          title: "Roasted and Grinded Coffee",
          weight: "1KG",
          price: "Contactus for price",
          desc: `A 1 KG Roasted and Grinded Coffee with Full bodied, Genuine, Coffee Arabica with distinct fragrant Aroma and Mocha Flavor.
          100% carefully selected indigenous Coffee Arabica.`,
        },
        {
          img: require("../../assets/product/p2.png"),
          title: "Roasted and Grinded Coffee",
          weight: "0.5 KG",
          price: "Contactus for price",
          desc: `A 0.5 KG Roasted and Grinded Coffee with Full bodied, Genuine, Coffee Arabica with distinct fragrant Aroma and Mocha Flavor.
          100% carefully selected indigenous Coffee Arabica.`,
        },
        {
          img: require("../../assets/product/p3.png"),
          title: "Roasted and Grinded Coffee",
          weight: "0.25 KG",
          price: "Contactus for price",
          desc: `A 0.25 KG Roasted and Grinded Coffee with Full bodied, Genuine, Coffee Arabica with distinct fragrant Aroma and Mocha Flavor.
          100% carefully selected indigenous Coffee Arabica.`,
        },
      ],
    };
  },
};
</script>